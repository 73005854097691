import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Link,
  Stack,
} from "@chakra-ui/react"
import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <Center h="calc(100vh - 4.5rem)" w="100vw">
      <Alert
        align="center"
        bg="red.700"
        color="white"
        flexDir="column"
        justify="center"
        maxW="95vw"
        px={[8, 10, 12]}
        py={24}
        rounded="lg"
        shadow="lg"
        status="warning"
        textAlign="center"
        variant="subtle"
        w="500px"
      >
        <AlertIcon boxSize="60px" color="red.300" />
        <AlertTitle
          fontFamily="heading"
          fontSize="4xl"
          lineHeight="normal"
          mt={10}
        >
          Whoops! That page doesn't exist.
        </AlertTitle>
        <AlertDescription fontSize="lg" mt={8}>
          <Box>
            Sorry about that! This page may have been removed, or it may never
            have existed at all. Either way, we've got other good ones for you
            to check out!
          </Box>
          <Stack
            direction={[`column`, `row`]}
            fontWeight="bold"
            justify="center"
            mt={12}
            spacing="24px"
          >
            <Link as={GatsbyLink} color="red.100" to="/">
              Go to the Home Page
            </Link>
            <Link as={GatsbyLink} color="red.100" to="/home-plans/">
              View Our Home Plans
            </Link>
          </Stack>
        </AlertDescription>
      </Alert>
    </Center>
  </Layout>
)

export default NotFoundPage
